/*
 * @Description: common hooks
 * @Author: kecraft
 * @Date: 2024-01-15 09:08:26
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-30 15:44:59
 * @FilePath: /impact-iotos-console/src/hooks/common.js
 */
import FileSaver from 'file-saver';
import { ElMessage } from "element-plus";
/**
 * 根据key，返回label
 * @param {*} key 枚举 key
 * @param {*} enums 枚举列表
 * @returns 
 */
const enumFilter = (key, enums) => {
  let str = ''
  enums.forEach(item => {
    if (item.key === key) {
      str = item.label;
    }
  })
  return str;
}

/**
 * fileSave json导出
 * @param {*} json 导出的json
 * @param {*} name 导出的文件名
 */
const fileSaveExportJson = (json, name) => {
  const blob = new Blob([JSON.stringify(json)], { type: "application/json;charset=utf-8" });
  FileSaver.saveAs(blob, name + '.json')
}

/**
 * 列表转换成树形结构
 * @param {*} list 树
 * @returns 
 */
const convertToTree = (list) => {
  let tree = [];
  list.forEach((item) => {
    if (item.parentId === "") {
      tree.push({ ...item });
    }
  });
  list.forEach(item => {
    let has = false;
    tree.forEach(treeItem => {
      if (treeItem.id === item.id) {
        has = true;
      }
    })
    if (!has) {
      findParentAndAddChild(tree, item);
    }
  })
  return tree;
}

/**
 * 找子节点
 * @param {*} arr 
 * @param {*} childItem 
 */
const findParentAndAddChild = (arr, childItem) => {
  for (let i = 0; i < arr.length; i++) {
    const parentItem = arr[i];
    if (childItem.parentId === parentItem.id) {
      if (!Array.isArray(parentItem.children)) {
        parentItem.children = [];
      }
      parentItem.children.push({ ...childItem });
      break;
    } else if (Array.isArray(parentItem.children)) {
      findParentAndAddChild(parentItem.children, childItem);
    }
  }
}

/**
 * 复制功能
 * @param {*} content 复制内容
 * @returns 
 */
const copyToClipboard = (content) => {
  if (!content) return;
  let textArea = document.createElement("textarea");
  textArea.value = content;
  textArea.style.position = "absolute";
  textArea.style.opacity = '0';
  textArea.style.left = "-999999px";
  textArea.style.top = "-999999px";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  return new Promise((res, rej) => {
    // 执行复制命令并移除文本框
    document.execCommand('copy') ? res(null) : rej();
    textArea.remove();
    ElMessage.success("复制成功!")
  });
}

/**
 * 导出csv
 * @param {*} str 
 */
const uploadCsvFile = (str) => {
  let uri = 'data:text/csv;charset=utf-8,\ufeff' + str;
  let downloadLink = document.createElement('a');
  downloadLink.href = uri;
  downloadLink.download = "设备";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  ElMessage.success("导出成功！")
}
export {
  enumFilter,
  fileSaveExportJson,
  convertToTree,
  copyToClipboard,
  uploadCsvFile,
}